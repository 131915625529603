import Axios from 'axios';
import { connect } from 'react-redux';
import Setup from '../components/Setup';
import { addServer, deploying, deployed, setServers } from '../actions/server';
import { updatingIdle, updatedIdle, updatingFallback, updatedFallback } from '../actions/role';

const axios = Axios.create({ withCredentials: true });

const deploy = ip => axios.post(`/api/servers/${ip}/deployment`);
const updateIdleRole = ip => axios.put(`/api/roles/idle`, { ip });
const updateFallbackRole = ip => axios.put(`/api/roles/fallback`, { ip });

const dispatches = {
  updateIdle: ip => dispatch => {
    dispatch(updatingIdle(ip));
    updateIdleRole(ip).then(() => {
      dispatch(updatedIdle(ip));
    });
  },
  updateFallback: ip => dispatch => {
    dispatch(updatingFallback(ip));
    updateFallbackRole(ip).then(() => {
      dispatch(updatedFallback(ip));
    });
  },
  addServer: () => (dispatch, getState) => {
    const all = getState().serverStore.servers;
    const name = `api-server-${all.length}`;
    axios.post('/api/servers', { name }).then(resp => {
      const server = resp.data;
      const ip = server.publicIP;
      dispatch(addServer(server));
      dispatch(deploying(ip));
      deploy(ip).then(() => {
        dispatch(deployed(ip));
      });
    });
  },
  setServers,
};

const state = state => {
  return ({
    setup: state.serverStore,
  });
}

export default connect(state, dispatches)(Setup);
