import { connect } from 'react-redux';
import App from '../components/App';

const dispatches = {
};

const state = (state, { location: { pathname } }) => {
  return ({
    initPath: pathname.endsWith('setup') ? 'setup' : 'status',
  });
}

export default connect(state, dispatches)(App);
