import React, { Component } from 'react';
import { Input, Button, Form, Icon } from 'antd';
import { Redirect } from 'react-router-dom';
import './Signup.css';

class LoginForm extends Component {
  handleSubmit = e => {
    const { onSubmit } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        onSubmit && onSubmit(values.email);
      }
    });
  };

  render() {
    const getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <Form layout="inline" onSubmit={this.handleSubmit} className="signup-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, pattern: /.+@(spidersheet|codasheet).com/, message: 'Support spidersheet.com and codasheet.com emails' }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="xxx@spidersheet.com or xxx@codasheet.com"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Get login url
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

class Signup extends Component {
  state = { email: '' };
  constructor(props) {
    super(props);
    this.handleCreateToken = this.handleCreateToken.bind(this);
  }
  handleCreateToken(email) {
    this.props.createToken(email);
  }
  render() {
    const { auth: { tokenCreating, tokenCreated, tokenFailResean, hasLogin }, returnUrl } = this.props;
    if (tokenCreating) {
      return <div>sending token to your email or stdout</div>;
    }
    if (tokenCreated) {
      return <div>open that link with browser</div>;
    }
    if (tokenFailResean) {
      return <div>error with {tokenFailResean}, try again</div>
    }
    if (hasLogin) {
      return <Redirect to={returnUrl} />
    }

    return (
      // <div>
      //   <form onSubmit={this.handleCreateToken}>
      //     <Input onBlur={this.handleEmailChange} />
      //     <Button onClick={this.handleCreateToken}>Login</Button>
      //   </form>
      // </div>
      <div className="signup-form-container">
        <WrappedNormalLoginForm onSubmit={this.handleCreateToken} />
      </div>
    );
  }
}

export default Signup;