import { connect } from 'react-redux';
import PrivateRoute from '../components/PrivateRoute';
import Axios from 'axios';
import { login } from '../actions/auth';


const testRequest = Axios.create({ withCredentials: true }).get('/api/auth/test')

const dispatches = {
  test: () => dispatch => {
    testRequest.then(({ data }) => {
      dispatch(login(data.email));
    }).catch((m) => {
      console.error(m);
    });
  },
};

const state = state => ({
  auth: state.authStore,
});

export default connect(state, dispatches)(PrivateRoute);
