import _ from 'lodash';
import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';

class LoginLoading extends Component {
  componentWillMount() {
    const tokenID = new URLSearchParams(window.location.search).get('token');
    this.props.login(tokenID);
  }
  render() {
    if (_.get(this.props, 'auth.hasLogin', false)) {
      return <Redirect to="/app/setup" />
    }
    const reason = _.get(this.props, 'auth.reason');
    if (reason) {
      return (
        <div>
          <h1>{reason}</h1>
          <NavLink to="/signup" >Go to sign up</NavLink>
        </div>
      );
    }
    return (
      <div>
        trying to login...
      </div>
    );
  }
}

export default LoginLoading;