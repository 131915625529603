import React, { Component } from 'react';
import ServersTable from './ServersTable';
import Axios from 'axios';
import ServerCommands from '../components/ServerCommands';

class Setup extends Component {
  state = { servers: [] };
  componentWillMount() {
    Axios.get('/api/servers/cluster').then((resp) => {
      const roles = (server, idle, fallback) => {
        const all = [];
        if (server.privateIP === idle || server.publicIP === idle) {
          all.push('idle')
        }
        if (server.privateIP === fallback || server.publicIP === fallback) {
          all.push('fallback')
        }
        return all;
      };
      const { servers, idle, fallback } = resp.data;
      const apiServers = servers.map(s => ({ ...s, roles: roles(s, idle, fallback), key: s.id }));
      this.props.setServers(apiServers);
    });
  }
  render() {
    return (
      <div>
        <ServerCommands addServer={this.props.addServer} />
        <ServersTable servers={this.props.setup.servers} updateIdle={this.props.updateIdle} updateFallback={this.props.updateFallback} />
      </div>
    );
  }
}

export default Setup;