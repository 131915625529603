export const Actions = {
  SET_SERVERS: 'setServers',
  ADD_SERVER: 'addServer',
  DEPLOYING: 'deploying',
  DEPLOYED: 'deployed',

  UPDATING_IDLE: 'updatingIdle',
  UPDATED_IDLE: 'updatedIdle',
  UPDATING_FALLBACK: 'updatingFallback',
  UPDATED_FALLBACK: 'updatedIdle',

  CREATING_TOKEN: 'creatingToken',
  CREATE_TOKEN_SUCC: 'createTokenSucc',
  CREATE_TOKEN_FAIL: 'createTokenFail',

  LOGIN: 'login',
  LOGIN_FAIL: 'loginFail',
  LOGOUT: 'logout',
};

export const Roles = {
  Idle: 'idle',
  Fallback: 'fallback',
};
