import { Actions } from "../constant";

const serverReducers = (state, action) => {
  switch (action.type) {
    case Actions.SET_SERVERS: {
      return { ...state, servers: action.servers };
    }
    case Actions.ADD_SERVER: {
      return { ...state, servers: state.servers.concat(action.server) };
    }
    case Actions.DEPLOYING: {
      const servers = state.servers.map(s => (s.publicIP !== action.ip ? s : ({ ...s, deploying: true })));
      return { ...state, servers }
    }
    case Actions.DEPLOYED: {
      const servers = state.servers.map(s => (s.publicIP !== action.ip ? s : ({ ...s, deploying: false })));
      return { ...state, servers }
    }
    default:
      return state;
  }
};

export default serverReducers;