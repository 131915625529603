import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
  componentWillMount() {
    if (!this.props.auth.hasLogin) {
      this.props.test();
    }
  }
  render() {
    const { component, auth, ...rest } = this.props;
    if (auth.hasLogin) {
      return <Route {...rest} component={component} />;
    }
    return <Redirect to={`/signup?returnUrl=${rest.location.pathname}`} />;
  }
}

export default PrivateRoute;