import React from 'react';

const Status = () => {
  return (
    <iframe id="server-status-container" title="Status" src="/ui">

    </iframe>
  );
};

export default Status;