import Axios from 'axios';
import { connect } from 'react-redux';
import LoginLoading from '../components/LoginLoading';
import { login, loginFail } from '../actions/auth';

const axios = Axios.create({ withCredentials: true });

const loginReqeust = tokenID => axios.get(`/api/auth/login?token=${tokenID}`)

const dispatches = {
  login: tokenID => dispatch => {
    loginReqeust(tokenID).then(({ data }) => {
      dispatch(login(data.email));
      console.log("login success");
    }).catch((err) => {
      dispatch(loginFail(err.message));
      console.log("login fail");
    });
  },
};

const state = state => ({
  setup: state.serverStore,
  auth: state.authStore,
});

export default connect(state, dispatches)(LoginLoading);
