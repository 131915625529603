import React, { Component } from 'react';
import { Table, Icon, Button } from 'antd';
import moment from 'moment';
import './ServersTable.scss';

const statusRender = (status, record) => (
  <span>
    {status === 'Running' && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
    {status === 'Stopped' && <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96" />}
    {status}
    {record.deploying && <Icon type="sync" spin />}
  </span>
);
const timeRender = time => `${moment(time).format('YYYY-MM-DD HH:mm:ss')}`;
const cpuRender = c => `${c} core`;
const memRender = m => `${(m / 1000).toFixed(1)}GiB`;
const rolesRender = roles => {
  return (
    <span>
      {roles.map(r => <span key={r} className={r + ' role'}>{r}</span>)}
    </span>
  )
};
const actionRender = (text, record) => {
  const { idling, fallbacking } = record;
  return (
    <Button.Group>
      <Button onClick={() => record.updateIdle(record.publicIP)} disabled={idling}>
        {idling && <Icon type="sync" spin />}
        {idling ? "idling" : "idle"}
      </Button>
      <Button onClick={() => record.updateFallback(record.publicIP)} disabled={fallbacking}>
        {fallbacking && <Icon type="sync" spin />}
        fallback
      </Button>
      {/* <Button>Normal</Button> */}
    </Button.Group>
  );
};

const columns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Status', dataIndex: 'status', key: 'status', render: statusRender },
  { title: 'StartTime', dataIndex: 'startTime', key: 'startTime', render: timeRender },
  { title: 'CPU', dataIndex: 'cpu', key: 'cpu', render: cpuRender },
  { title: 'Mem', dataIndex: 'memory', key: 'memory', render: memRender },
  { title: 'PublicIP', dataIndex: 'publicIP', key: 'publicIP' },
  { title: 'PrivateIP', dataIndex: 'privateIP', key: 'privateIP' },
  { title: 'Roles', dataIndex: 'roles', key: 'roles', render: rolesRender },
  { title: 'ChangeToActions', key: 'action', render: actionRender },
];

class ServersTable extends Component {
  getTableDataSource() {
    const { servers, updateIdle, updateFallback } = this.props;
    return servers.map(s => ({ ...s, updateIdle, updateFallback }));
  }
  render() {
    return (
      <Table className="serversTable" columns={columns} dataSource={this.getTableDataSource()} />
    );
  }
}

export default ServersTable;