import { Actions } from "../constant";

const authReducers = (state = { email: '', hasLogin: false }, action) => {
  switch (action.type) {
    case Actions.LOGIN:
      return { ...state, hasLogin: true, email: action.email };
    case Actions.LOGIN_FAIL:
      return { ...state, hasLogin: false, reason: action.reason };
    case Actions.LOGOUT:
      return { ...state, hasLogin: false, email: '' };
    case Actions.CREATING_TOKEN:
      return { ...state, tokenCreating: true };
    case Actions.CREATE_TOKEN_SUCC:
      return { ...state, tokenCreating: false, tokenCreated: true };
    case Actions.CREATE_TOKEN_FAIL:
      return { ...state, tokenCreating: false, tokenCreated: false, tokenFailResean: action.result };
    default:
      return state;
  }
};

export default authReducers;
