import React, { Component } from 'react';
import { Button } from 'antd';

export default class ServerCommands extends Component {
  constructor() {
    super();
    this.addAPIServer = this.addAPIServer.bind(this);
  }
  addAPIServer() {
    this.props.addServer();
  }
  render() {
    return (
      <Button.Group className="table-operations">
        <Button onClick={this.addAPIServer}>Add api server</Button>
      </Button.Group>
    );
  }
}
