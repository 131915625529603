import { combineReducers } from 'redux';
import serverReducers from './server';
import roleReducers from './role';
import authReducers from './auth';

const initState = {
  servers: [],
  tokenCreating: false,
  tokenCreated: false,
  tokenFailResean: '',
};


const combineReducer = (...reducers) => {
  return (state = initState, action) => {
    let newState = null;
    reducers.some(r => {
      newState = r(state, action);
      return newState !== state;
    });
    return newState;
  };
};

export default combineReducers({
  serverStore: combineReducer(serverReducers, roleReducers, authReducers),
  authStore: authReducers,
});
