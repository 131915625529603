import React, { Component } from "react";
import { Route, NavLink } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';
import { Layout } from 'antd';
import Status from './Status';
import Setup from '../containers/SetupContainer';
import "./App.css";

const { Header, Footer, Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { current: props.initPath || 'status' };
  }

  handlePageSelect = e => {
    this.setState({ current: e.key });
  }

  render() {
    return (
      <div className="App" >
        <Layout>
          <Header>
            <Menu onClick={this.handlePageSelect} theme="dark" selectedKeys={[this.state.current]} mode="horizontal" style={{ lineHeight: '64px' }}>
              <Menu.Item key="status">
                <NavLink exact to="/app/status">
                  <Icon type="info" /> All server status
                </NavLink>
              </Menu.Item>
              <Menu.Item key="setup">
                <NavLink exact to="/app/setup">
                  <Icon type="setting" />Setup
                </NavLink>
              </Menu.Item>
            </Menu>
          </Header>
          <Content>
            <div>
              <Route path="/" exact component={Status} />
              <Route path="/app/status" component={Status} />
              <Route path="/app/setup" component={Setup} />
            </div>
          </Content>
          <Footer>spdiersheet.com</Footer>
        </Layout>
      </div>
    );
  }
}

export default App;
