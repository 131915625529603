import { Actions, Roles } from "../constant";
import { hasIP, hasRole } from "../utils/stateUtils";

const roleReducers = (state , action) => {
  switch (action.type) {
    case Actions.UPDATING_IDLE: {
      const servers = state.servers.map(s => {
        if (!hasIP(s, action.ip)) {
          return hasRole(s, Roles.Idle) ? { ...s, roles: s.roles.filter(r => r !== Roles.Idle) } : s;
        }
        return { ...s, idling: true };
      });
      return { ...state, servers };
    }
    case Actions.UPDATED_IDLE: {
      const servers = state.servers.map(s => {
        if (!hasIP(s, action.ip)) {
          return s;
        }
        const roles = hasRole(s, Roles.Idle) ? s.roles : s.roles.concat(Roles.Idle);
        return { ...s, idling: false, roles };
      });
      return { ...state, servers };
    }
    case Actions.UPDATING_FALLBACK: {
      const servers = state.servers.map(s => {
        if (!hasIP(s, action.ip)) {
          return s;
        }
        return { ...s, fallbacking: true };
      });
      return { ...state, servers };
    }
    case Actions.UPDATED_FALLBACK: {
      const servers = state.servers.map(s => {
        if (!hasIP(s, action.ip)) {
          return s;
        }
        return { ...s, fallbacking: false, roles: s.roles.concat('fallback') };
      });
      return { ...state, servers };
    }
    default:
      return state;
  }
};

export default roleReducers;
