import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { LocaleProvider } from 'antd';
import zhCN from 'antd/es/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './containers/AppContainer';
import manageUI from './reducers';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import Signup from './containers/SignupContainer';
import Login from './containers/LoginContainer';
import PrivateRoute from './containers/PrivateRoute';

const store = createStore(manageUI, {}, applyMiddleware(thunk));

moment.locale('zh-cn');

class Root extends React.Component {
  render() {
    return (
      <Provider store={store} >
        <LocaleProvider locale={zhCN}>
          <Router>
            <Switch>
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={App} />
            </Switch>
          </Router>
        </LocaleProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
