import Axios from 'axios';
import { connect } from 'react-redux';
import Signup from '../components/Signup';
import { creatingToken, createTokenSucc, createTokenFail } from '../actions/auth';

const axios = Axios.create({ withCredentials: true });

const dispatches = {
  createToken: email => (dispatch) => {
    dispatch(creatingToken())
    axios.post('/api/auth/token', { email }).then(resp => {
      dispatch(createTokenSucc());
    }).catch((reject) => {
      dispatch(createTokenFail("fail"));
    });
  },
};

const state = (s, { location: { search } }) => {
  return ({
    setup: s.serverStore,
    auth: s.authStore,
    returnUrl: new URLSearchParams(window.location.search).get('returnUrl') || '/app/setup',
  });
}

export default connect(state, dispatches)(Signup);
